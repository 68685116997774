<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        Marchés > Tableau de bord
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Situation des Programmes
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      <div class="div_left">
        <button class="btn btn-blue"
                @click="downloadExport()">
          Exporter
        </button>
      </div>
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- /////////////////////////////////////// ZONE IMPRIMABLE /////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Situations_programme_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-2">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center my-3">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des Programmes
                du {{ annee_debut.split("-")[2]+"-"+annee_debut.split("-")[1]+"-"+annee_debut.split("-")[0] }}
                au {{ annee_fin.split("-")[2]+"-"+annee_fin.split("-")[1]+"-"+annee_fin.split("-")[0] }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table table-blue text-center"> 
            <thead>
              <tr>
                <th class="tht-dark-blue text-left"
                    scope="col">Programme</th>
                <th class="tht-dark-blue"
                    scope="col">Montant prévisionnel</th>
                <th class="tht-dark-blue"
                    scope="col">Engagements</th>
                <th class="tht-dark-blue"
                    scope="col">Décomptes reçus</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à recevoir</th>
                <th class="tht-dark-blue"
                    scope="col">Paiements effectués</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à payer sur décomptes reçus</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à payer sur engagements</th>
              </tr>
            </thead>
            <tbody>
              <tr scope="row"
                  v-for="(program,programKey) in decomptePer"
                  :key="programKey"
                  @click="launchUpdate(program.id)">
                <td class="text-left">{{program.programme}}</td>
                <td class="text-right">{{Math.round(Number(program.montant)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.engagement)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.decompte_recu)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(program.engagement-program.decompte_recu)).toLocaleString() }}
                </td>
                <td class="text-right">{{Math.round(Number(program.decompte_paye)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(program.decompte_en_attente_payement)).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{Math.round(Number((program.engagement-program.decompte_recu)
                    +program.decompte_en_attente_payement)).toLocaleString() }}
                </td>

              </tr>
            </tbody>
            <tfoot>
              <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
              <tr scope="row"
                  class="tht-blue">
                <td class="text-left text-uppercase">Total</td>
                <td class="text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.engagement)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.engagement-totaux.recu)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number((totaux.engagement-totaux.recu)+totaux.en_attente_payement)).toLocaleString()}}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row mx-auto mt-2">
      <table class="table table-blue text-center"> 
        <thead>
          <tr>
            <th class="tht-dark-blue text-left"
                scope="col"></th>
            <th class="tht-dark-blue text-left"
                scope="col">Programme</th>
            <th class="tht-dark-blue"
                scope="col">Montant prévisionnel</th>
            <th class="tht-dark-blue"
                scope="col">Engagements</th>
            <th class="tht-dark-blue"
                scope="col">Décomptes reçus</th>
            <th class="tht-dark-blue"
                scope="col">Restes à recevoir</th>
            <th class="tht-dark-blue"
                scope="col">Paiements effectués</th>
            <th class="tht-dark-blue"
                scope="col">Restes à payer sur décomptes reçus</th>
            <th class="tht-dark-blue"
                scope="col">Restes à payer sur engagements</th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row"
              v-for="(program,programKey) in decomptePer"
              :key="programKey">
            <td class="justify-content-center">
              <input type="checkbox" 
                     class="form-check-input" 
                     name="" 
                     id=""
                     :ref="'program'+program.id"
                     @click=selection(program)>
            </td>
            <td class="text-left"
                @click="launchUpdate(program.id)">{{program.programme}}</td>
            <td class="text-right">{{Math.round(Number(program.montant)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(program.engagement)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(program.decompte_recu)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(program.engagement-program.decompte_recu)).toLocaleString() }}
            </td>
            <td class="text-right">{{Math.round(Number(program.decompte_paye)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(program.decompte_en_attente_payement)).toLocaleString() }}
            </td>
            <td class="text-right">
              {{Math.round(Number((program.engagement-program.decompte_recu)
                +program.decompte_en_attente_payement)).toLocaleString() }}
            </td>

          </tr>
        </tbody>
        <tfoot>
          <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
          <tr scope="row"
              class="tht-blue">
            <td class="text-left text-uppercase"
                colspan="2">Total</td>
            <td class="text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.engagement)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.engagement-totaux.recu)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number((totaux.engagement-totaux.recu)+totaux.en_attente_payement)).toLocaleString()}}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<style>
  /* .table tbody td,
  .table tfoot td,
  .table thead th{
    font-size: 0.54em;
  } */
  .table tbody tr{
    cursor: pointer;
  }
</style>
<script>
import {mapActions, mapGetters,mapMutations} from "vuex"
import VueHtml2pdf from 'vue-html2pdf'

export default({
  name:"ProgrammeBoard",
  components: {
    VueHtml2pdf,
  },
  data:() =>({
    decomptePer:[],
    index:1,
    dateJour:"",
    loaded:false,
    annee_debut:new Date().getFullYear()+"-01-01",
    annee_fin:new Date().getFullYear()+"-12-31",
    exportData:{  
      contenue:[],
      totaux:"",
    },
    user:"",
    exportationLoaded:false,
    exportFile:"",
    exportation:{
      titre:"Liste des programmes",
      contenu:[],
      entete:[
        "Programme",
        "Montant prévisionnel",
        "Engagements",
        "Décomptes reçus",
        "Reste à recevoir",
        "Paiements effectués",
        "Reste à payer sur décomptes reçus",
        "Reste à payer sur engagements"
      ],
      nom_utilisateur:"",
      departement:"",
      date_impression:"",
      totaux:{
        programme : "Totaux",
        montant : 0,
        engagement : 0,
        decompte_recu : 0,
        reste_recevoir : 0,
        decompte_paye : 0,
        decompte_en_attente_payement: 0,
        // decompte_en_attente_payement_anterieure : per.decompte_en_attente_payement_anterieure,
        reste_payer_engagement : 0,
      }
    },
    totaux:{
      montant:0,
      recu:0,
      paye:0,
      en_attente_payement:0,
      en_attente_payement_anterieure:0
    }
  }),
  watch:{
    TableauDecomptePer(){
      if (this.TableauDecomptePer.donnees) {
        this.decomptePer = this.TableauDecomptePer.donnees
        // this.exportData.contenue = this.TableauDecomptePer.donnees 
        // console.log(this.decomptePer)
        this.exportation.contenu=[]
        this.totaux.montant=0
        this.totaux.recu=0
        this.totaux.paye=0
        this.totaux.engagement=0
        this.totaux.en_attente_payement=0
        this.totaux.en_attente_payement_anterieure=0
        this.exportation.contenu=[]
        console.log(this.$refs)
        
        this.TableauDecomptePer.donnees.forEach(per => {
          // this.$refs["program"+per.id][0].checked=true
          // this.exportation.contenu.push({
          //   programme : per.programme,
          //   montant : per.montant,
          //   engagement : per.engagement,
          //   decompte_recu : per.decompte_recu,
          //   reste_recevoir : Number(per.engagement-per.decompte_recu),
          //   decompte_paye : per.decompte_paye,
          //   decompte_en_attente_payement: per.decompte_en_attente_payement,
          //   // decompte_en_attente_payement_anterieure : per.decompte_en_attente_payement_anterieure,
          //   reste_payer_engagement : Number(per.engagement-per.decompte_recu) + Number(per.decompte_en_attente_payement),
          // })

          this.totaux.recu+= per.decompte_recu
          // console.log(this.totaux)
          this.totaux.montant+= per.montant
          this.totaux.paye+= per.decompte_paye
          this.totaux.engagement+= per.engagement
          this.totaux.en_attente_payement+= per.decompte_en_attente_payement
          this.totaux.en_attente_payement_anterieure+= per.decompte_en_attente_payement_anterieure
        })
        // this.exportation.totaux.montant=this.totaux.montant
        // this.exportation.totaux.decompte_paye=this.totaux.paye
        // this.exportation.totaux.decompte_recu=this.totaux.recu
        // this.exportation.totaux.engagement=this.totaux.engagement
        // this.exportation.totaux.decompte_en_attente_payement=this.totaux.en_attente_payement
        // // this.exportation.totaux.decompte_en_attente_payement_anterieure=this.totaux.en_attente_payement_anterieure
        // this.exportation.totaux.reste_recevoir= Number(this.totaux.engagement-this.totaux.recu)
        // this.exportation.totaux.reste_payer_engagement=Number((this.totaux.engagement-this.totaux.recu)+this.totaux.en_attente_payement)
        // // this.exportData.totaux = this.totaux
        // console.log(this.exportation)
        this.loaded=true
        // this.TableauDecomptePer.donnees.forEach(per => {
        //   this.$refs["program"+per.id][0].checked=true
        // })
        this.setTableauDecomptePer("")        
      }
    },
    exportLink(){
      if (this.exportLink.url) {
        const link = document.createElement('a')
        link.href = process.env.VUE_APP_UPLOAD+this.exportLink.url
        link.download = this.exportLink.url
        link.click()
        URL.revokeObjectURL(link.href)
        
      }
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    // remplir la case date_impression pour l'exportation
    var jour = new Date()
    this.exportation.date_impression=""
    if (jour.getDate() < 10) {
      this.exportation.date_impression ="0" 
    }
    this.exportation.date_impression+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.exportation.date_impression +="0"
    }
    this.exportation.date_impression+=(jour.getMonth()+1)+"-"
    this.exportation.date_impression += jour.getFullYear()
    
    // remplir la case nom_utilisateur pour l'exportation
    this.exportation.nom_utilisateur = userdetails.nom
    
    // remplir la case departement pour l'exportation
    switch (this.user[0]) {
      case "DT":
        this.exportation.departement = "Direction Technique"
        break
      case "DCG":
        this.exportation.departement = "Direction du Contrôle de Gestion"
        break
      case "DAF":
        this.exportation.departement = "Direction Administrative et Financière/ Ingenierie Financière"
        break
      case "ADMIN":
        this.exportation.departement = "Administrateur"
        break
      default:        
        this.exportation.departement = "Autre"
        break
    }
    
    this.loaded=false
    this.getTableauDecomptePer({
      annee_debut:this.annee_debut,
      annee_fin:this.annee_fin,
      charge:"PER",
    })
  },
  computed:{
    ...mapGetters(["TableauDecomptePer","exportLink","failExportLink"])
  },
  methods:{
    ...mapActions(["getTableauDecomptePer","exporterDonnees"]),
    ...mapMutations(["setTableauDecomptePer","setExportLink","setFailExportLink"]),
    launchUpdate(id) {
      this.$router.push({ name: "ActiviteBoard", params: { id: id } })
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    selection (program) {
      this.exportationLoaded=false
      this.exportation.totaux.montant = 0
      this.exportation.totaux.decompte_paye = 0
      this.exportation.totaux.decompte_recu = 0
      this.exportation.totaux.engagement = 0
      this.exportation.totaux.decompte_en_attente_payement = 0
      this.exportation.totaux.reste_recevoir = 0
      this.exportation.totaux.reste_payer_engagement = 0
      if (this.$refs["program"+program.id][0].checked) {
        this.exportation.contenu.push(
          {
            programme: program.programme,
            montant: program.montant,
            engagement: program.engagement,
            decompte_recu: program.decompte_recu,
            reste_recevoir: Number(program.engagement-program.decompte_recu),
            decompte_paye: program.decompte_paye,
            decompte_en_attente_payement: program.decompte_en_attente_payement,
            reste_payer_engagement: Number((program.engagement-program.decompte_recu)+program.decompte_en_attente_payement),
          }
        )
      }else{
        var position=null
        for (let index = 0; index < this.exportation.contenu.length; index++) {
          if(program.id==this.exportation.contenu[index].id){
            position=index
          }
        }
        console.log(position)
        if (position!=null) {
          this.exportation.contenu.splice(position,1)
        }
      }
      this.exportation.contenu.forEach(programme => {
        this.exportation.totaux.montant += programme.montant
        this.exportation.totaux.decompte_paye += programme.decompte_paye
        this.exportation.totaux.decompte_recu += programme.decompte_recu
        this.exportation.totaux.engagement += programme.engagement
        this.exportation.totaux.decompte_en_attente_payement += programme.decompte_en_attente_payement
        this.exportation.totaux.reste_recevoir += Number(programme.reste_recevoir)
        this.exportation.totaux.reste_payer_engagement += Number(programme.reste_payer_engagement)
      })
      console.log(this.exportation)
    },
    changeYear(){
      this.loaded = false
      console.log(this.exportData)

      this.getTableauDecomptePer({
        annee_debut:this.annee_debut,
        annee_fin:this.annee_fin,
        charge:"PER",
        exportation:this.exportData
      })
    },
    downloadExport(){
      this.exporterDonnees(this.exportation)
    }
  }
})
</script>
